import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = () => {
  return (
    <Layout light={false}>
      <SEO
        title={"Welcome"}
        description={"The meeting place for people, products, and great design"}
      />
      <section className="slide kenBurns">
        <div className="content">
          <div className="container">
            <div className="wrap">
              <div className="fix-10x-12">
                <h1 className="ae-1 largest margin-bottom-2">
                  Product design education for everyone.
                </h1>
                <p className="ae-2 large margin-bottom-5 fix-6x-12">
                  <span className="opacity-8">
                    Learn how to design and build delightful products with The
                    Unflux - a learning place for creative minds like you.
                  </span>
                </p>
                <a
                  className="button rounded margin-top-1 ae-5 fromCenter"
                  href="/learn"
                >
                  Start Learning
                </a>
                <a
                  className="button transparent rounded margin-top-1 ae-6 fromCenter"
                  href="/join_unflux"
                >
                  <svg>
                    <use
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      xlinkHref="/assets/svg/icons.svg#flat-star-icon"
                    ></use>
                  </svg>
                  Become a member
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="background"
          style={{ backgroundImage: "url(/assets/img/background/img-11.jpg)" }}
        ></div>
      </section>
    </Layout>
  );
};

export default IndexPage;
